.tab {
  background-color: var(--bg);
  width: fit-content;
  margin: auto;
}

.tabHeader {
  color: var(--dark);
  background-color: var(--dark);
  margin-right: auto;
}

.tabHeader input {
  background-color: var(--dark);
  width: 135px;
  margin-bottom: 0;
  margin-left: 0;
}

.tabHeader input.selected {
  background-color: var(--bg);
}

.tabContent {
  padding: 10px 10px 0;
}
