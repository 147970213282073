body {
  margin: 0;
  height: 80vh;
  background: var(--dark) url(res/frame/background.png);
  background-attachment: fixed;
  /*cursor:url(res/frame/cu.gif)12 13, auto;*/
  font-family: Helvetica;
  font-size: 16px;
  line-height: 150%;
  color-scheme: dark light;
  color: var(--main);
  --main: #f60;
  --dark: #222;
  --bg: #333;
  --grey: #444;
  --text: #ccc;
  --active: #f80;
  --active-bg: #530;
  --input-bg: #500;
  --valid: #2e2;
  --invalid: #e00;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-color-scheme: dark) {
}

@font-face {
  font-family: customFont;
  src: url(res/frame/Black.ttf);
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  background: var(--bg);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
}

::-webkit-scrollbar-corner {
  background-color: var(--dark);
}

p {
  font-size: 100%;
}

h1 {
  font-size: 250%;
  line-height: 120%;
}

h2 {
  font-size: 180%;
  line-height: 120%;
}

h3 {
  font-size: 120%;
  line-height: 120%;
}

a {
  transition: 0.5s;
  color: var(--active);
  text-decoration: none;
  font-size: 100%;
  font-variant: small-caps;
}

a:hover {
  color: #0f0;
}

a:active {
  color: var(--active);
}

a:visited {
  color: #e40;
}

a:visited:hover {
  color: #af0;
}

.side {
  float: left;
  margin: 1%;
  width: 36%;
}

.section {
  float: none;
  width: 100%;
}
