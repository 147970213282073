.previewGIFs {
  margin: 1%;
  padding: 0;
  display: block;
  width: 98%;
}

.previewGIFs:hover {
  width: 100%;
  margin: 0;
}

#GIFs td {
  width: 25%;
}

header {
  border-bottom: 2px solid var(--main);
}

#logo {
  cursor: pointer;
  display: contents;
}

#logo img {
  transition: 0.5s;
  margin: 6% auto 0;
  width: 32%;
}

#logo img:hover {
  margin: 5% auto 0;
  width: 35%;
}

.medium {
  display: inline-block;
  margin: 10px;
  min-width: 200px;
  width: 40%;
  max-width: 500px;
}

.large {
  display: inline-block;
  min-width: 200px;
  width: 90%;
  max-width: 1000px;
}

#quote {
  margin: 2%;
  font: italic 3vw Trajan;
  text-align: center;
}

#vill {
  flex-direction: column;
  display: flex;
  text-align: left;
  width: 90%;
  max-width: 1000px;
  margin: auto;
  background-color: #222;
}

#vill > a {
  flex-grow: 0;
  margin: 10px;
  margin-bottom: 3px;
  width: 250px;
  float: left;
}

#vill div {
  flex-grow: 1;
  margin: 10px;
}

#vill > div > a {
  margin-right: 100px;
  float: left;
}

#vill p {
  margin-top: 0;
}

#vill h3 {
  margin-top: 0;
}

@media (min-width: 701px) {
  .flex {
    flex-direction: row;
  }
  #vill {
    flex-direction: row;
  }
}

/* Prevents js from overriding the nav display property which would cause the nav to disappear on landscape */
@media (min-width: 701px) {
  header {
    background-position: 0 40px;
    margin-top: 40px;
  }
}
