#avatar {
  width: 32px;
  height: 32px;
}

nav {
  z-index: 5;
  position: fixed;
  top: 0;
  width: 100%;
  height: 42px;
  background: #222;
  border-bottom: 2px solid var(--main);
}

nav .menuEntry img {
  margin: -6px -5px;
  height: 35px;
}

nav .menuEntry {
  float: left;
  color: var(--text);
  padding: 10px;
  margin: 0;
  /*cursor:url(res/frame/Cursor.png)1 1,auto;*/
  height: 22px;
}

nav .menuEntry:hover {
  background: var(--bg);
  color: #f80;
}

nav .menuEntry:active {
  color: var(--active);
}

nav .show {
  display: block;
}

nav .menuEntry:last-child {
  float: right;
  background: #322;
  width: auto;
  height: 42px;
  padding: 0;
}

nav .menuEntry:last-child p {
  float: left;
  margin: 11px 10px;
}

nav .menuEntry:last-child #avatar {
  width: 36px;
  height: 36px;
  float: left;
  margin: 3px 0 3px 3px;
}

#menuToggle {
  display: none;
}

main {
  margin-top: 44px;
}

@media (max-width: 700px) {
  main {
    margin-top: 0;
  }

  nav {
    position: fixed;
    border: none;
    width: 200px;
    right: 0;
    height: 0;
  }

  nav .menuEntry {
    background: #222;
    width: 100%;
    height: 20px;
    text-align: left;
    float: none;
    padding: 13px 10px 9px;
    display: none;
  }

  #menuToggle {
    z-index: 10;
    padding: 0;
    position: fixed;
    display: block;
    cursor: pointer;
    top: 0;
    right: 0;
    background: #222a;
    border: none;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 36px;
    height: 6px;
    margin: 6px 6px;
    background: #e60;
    transition: 0.4s;
  }

  .change .bar1 {
    -webkit-transform: translate(3px, 12px) rotate(-45deg);
    transform: translate(3px, 12px) rotate(-45deg);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: translate(3px, -12px) rotate(45deg);
    transform: translate(3px, -12px) rotate(45deg);
  }
}

/* Prevents js from overriding the nav display property which would cause the nav to disappear on landscape */
@media (min-width: 701px) {
  nav .menuEntry {
    display: block !important;
  }
}
