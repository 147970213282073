.flexDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.card {
  background: url(/src/res/frame/background.png) center/100px;
  box-shadow: 0 8px 16px 0 black;
  border-radius: 20px;
  border: 1px solid black;
  overflow: hidden;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black, 1px 0 0 black, -1px 0 0 black,
    0 1px 0 black, 0 -1px 0 black;
  width: 270px;
  height: 200px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 var(--active);
  border: 1px solid var(--active);
}

.card div {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: auto;
  height: 100%;
}

.card a {
  margin-top: -40px;
  font-variant: normal;
  height: 100%;
}

#favorite {
  position: relative;
  width: 30px;
  left: 240px;
  font-size: 25px;
  line-height: 20px;
  border-radius: 0 30%;
}

#favorite:hover {
  color: var(--main);
}

input {
  color: var(--text);
  background: var(--input-bg);
  font-size: 100%;
  width: 240px;
  padding: 5px;
  border: 0;
  border-radius: 0;
  margin-bottom: 10px;
  cursor: pointer;
}

input[type='checkbox'],
[type='radio'] {
  width: 20px;
}

input[type='number'] {
  float: none;
  margin-right: 10px;
  width: 50px;
}

input[type='text'] {
  cursor: text;
}

input:focus {
  background: var(--active-bg);
}

input[type='button'] {
  background: var(--grey);
  width: 250px;
}

input[type='submit'] {
  background: var(--grey);
  width: 250px;
  border: 0;
  border-radius: 0;
}

input[name='upgrade'],
input[name='downgrade'],
input[name='delete'] {
  width: 32px;
  border-radius: 20px;
}

input[type='submit']:active {
  background: var(--active);
}

input[type='file'] {
  position: absolute;
  width: 250px;
  height: 50px;
  padding: 0;
  margin: 0;
  opacity: 0;
}

input.valid {
  color: var(--valid);
}

input.invalid {
  color: var(--invalid);
}

input:disabled {
  cursor: default;
  color: var(--grey);
  background: var(--dark);
}

input:active:not(:disabled) {
  background: var(--active);
}

main h1 {
  padding-top: 20px;
}

main h2 {
  padding-top: 20px;
}

canvas.reactive {
  width: 100%;
  max-width: 1000px;
  display: block;
  margin: auto;
  touch-action: none;
}

#mobileWarning {
  color: var(--invalid);
  margin-top: 70;
}

.picEvo {
  float: left;
  cursor: pointer;
  width: 20%;
}

#FileUpload {
  width: 250px;
  height: 50px;
}

.crop {
  background: black;
  overflow: hidden;
  float: left;
  width: 25%;
  height: 25vw;
}

.crop * {
  position: relative;
  left: 50%;
  top: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: 145%;
  max-height: 145%;
  transform: translate(-50%, -50%);
}

.image {
  cursor: pointer;
  width: 200px;
}

.fullscreen {
  position: fixed;
  top: 0;
  display: grid;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
}

.fullscreen img {
  min-width: 60%;
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
  margin: auto;
}

.fullscreen input {
  position: absolute;
  font-size: 24px;
  width: 50px;
  height: 50px;
  min-width: 40px;
  min-height: 40px;
  background: rgba(100, 100, 100, 0.4);
  border: 0;
}

.fullscreen > .close {
  top: 50px;
  right: 5px;
}

.fullscreen > .left {
  top: 50%;
  left: 5px;
}

.fullscreen > .right {
  top: 50%;
  right: 5px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 150px;
  margin-top: -75px;
  margin-left: -150px;
  background: url(/src/res/frame/background.png) center/100px;
  box-shadow: 0 8px 16px 0 black;
  border-radius: 20px;
  border: 1px solid black;
  overflow: hidden;
}

.modal > input {
  top: 0;
  right: 0;
  border-radius: 0 20px;
}

.modal > p {
  margin-top: 50px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
