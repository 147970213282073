.toggleButton {
  width: fit-content;
  padding: 5px;
  margin-right: 10px;
  line-height: normal;
  color: #bbb;
  background: #a20;
  cursor: pointer;
  display: inline-block;
}

.toggleButton div {
  width: 20px;
  height: 18px;
  display: block;
  margin-right: 5px;
  float: left;
  color: #a20;
  background: #aaa;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}

.toggleButton div.toggleOn {
  margin-right: 0;
  margin-left: 5px;
  float: right;
}
