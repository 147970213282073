input[name='delete'] {
  color: #aaa;
  background-color: #a20;
  cursor: pointer;
  border: 0;
  border-radius: 10px;
}
/*TODO remove duplicate css*/
input[name='delete']:hover {
  background-color: #a50;
}

input[name='delete']:active {
  background: #4f4;
}

input[name='password'] {
  color: #aaa;
  background-color: #a20;
  text-align: center;
  width: 250px;
  height: 30px;
  font-size: 18px;
  border: 0;
  border-radius: 10px;
}

input[name='password']:hover {
  background-color: #a50;
}

#files {
  position: fixed;
  top: 40;
  width: 40%;
  z-index: 1;
  text-align: left;
}

#notes {
  position: fixed;
  right: 0;
  top: 40;
  border: 0;
  border-radius: 10px;
  color: #f00;
  background: transparent;
  width: 60%;
  height: 80%;
  z-index: 1;
}

#fileButton {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2;
}

#fileInput {
  position: absolute;
  margin: 5px;
  width: 100px;
  height: 100px;
  opacity: 0;
}

#fileInput:hover {
  margin: 0;
  width: 110px;
  height: 110px;
}

#fileInput:hover ~ #UploadPic {
  margin: 0;
  width: 110px;
  height: 110px;
}

#UploadPic {
  margin: 5px;
  width: 100px;
  height: 100px;
}

#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.4;
  background: #3e3;
  display: none;
  width: 100%;
  height: 100%;
  z-index: 2;
}

#file {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

#lock {
  position: fixed;
  top: 0;
  left: 0;
  background: #222 url(/src/res/frame/background.png);
  width: 100%;
  height: 100%;
  z-index: 3;
}

#login {
  position: fixed;
  top: 47%;
  left: 42%;
  z-index: 4;
}
